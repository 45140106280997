<script>
import CashflowCard from "@/components/CashflowCard.vue";
import apiFinance from "@/api/finance"
import apiFinanceCashflow from "@/api/finance-cashflow"
import ScrollingPagination from "@/components/ScrollingPagination.vue";
import {useDisplay} from "vuetify";
import {mapState} from "vuex";
import CashflowEditDialog from "@/components/CashflowEditDialog.vue";
import CashflowCopyDialog from "@/components/CashflowCopyDialog.vue";

export default {
	setup () {
		// Destructure only the keys we want to use
		const { mdAndDown } = useDisplay();
		return { mdAndDown }
	},


	name: "CashflowPage",


	components: {
		CashflowEditDialog,
		CashflowCopyDialog,
		CashflowCard,
		ScrollingPagination,
	},


	data() {
		return {
			wsCashflow: null,
			pageTitle: 'Cashflow',
			tab: '',
			paymentSystemSelected: [],
			creatorsSelected: [],
			search: '',
			page: 1,
			items: [],
			itemsCount: null,
			itemsLoading: false,
			itemsLoaded: false,
			paymentSystems: [],
			paymentSystemsLoading: false,
			paymentSystemsLoaded: false,
			creators: [],
			creatorsLoading: false,
			creatorsLoaded: false,
			pageLoaded: false,
			editDialogOpen: false,
			editedIndex: -1,
			editedItem: {},
			defaultItem: {},
			copyDialogOpened: false,
			copiedItem: {},
			copiedIndex: -1,
		}
	},


	computed: {
		...mapState ({
			userInfo: state => state.user.info,
			userLoading: state => state.user.userLoading,
			userLoaded: state => state.user.userLoaded,
		}),
		selectAllSystems () {
			return this.paymentSystemSelected.length === this.paymentSystems.length
		},
		selectSomeSystems () {
			return this.paymentSystemSelected.length > 0
		},
		selectAllCreators () {
			return this.creatorsSelected.length === this.creators.length
		},
		selectSomeCreators () {
			return this.creatorsSelected.length > 0
		},
	},


	created() {
		if(this.userLoaded) {
			this.connectCashflowsWS();
		}
		if (localStorage.status_сashflow) {
			this.tab = localStorage.status_сashflow
		}
		if (localStorage.payment_system) {
			this.paymentSystemSelected = localStorage.payment_system.split(',')
		}
		this.paginationReset();
		this.getRequests();
		this.getPaymentSystems();
		this.getCreators();
	},


	watch: {
		userLoaded(value) {
			if (value) {
				this.connectCashflowsWS();
			}
		},
		search() {
			clearTimeout(this.timer);
			this.timer = setTimeout(() => {
				this.paginationReset();
				this.getRequests()
			}, 400);
		},
		tab() {
			if (this.itemsLoaded) {
				this.paginationReset();
				this.getRequests();
				localStorage.status_сashflow = this.tab
			}
		},
		paymentSystemSelected() {
			localStorage.payment_system = this.paymentSystemSelected
			if (this.itemsLoaded) {
				this.paginationReset();
				this.getRequests();
			}

		},
		creatorsSelected() {
			if (this.itemsLoaded) {
				this.paginationReset();
				this.getRequests();
				// localStorage.status = this.statusSelected
			}
		},
	},


	unmounted() {
		if (this.wsCashflow) {
			this.wsCashflow.close(1000, 'работа закончена');
		}
	},


	methods: {
		connectCashflowsWS() {
			const thisApp = this;
			const BASE_URL = (process.env.VUE_APP_URL).split('//')[1].split('/')[0];
			if (this.userLoaded) {
				this.wsCashflow = new WebSocket(`wss://${BASE_URL}/ws/request-cashflows/`);
				this.wsCashflow.onmessage = function(event) {
					let json = JSON.parse(event.data);
					console.log(json);
					if (json.event_type === 'updated') {
						thisApp.handleUpdate(json.data)
					}
					if (json.event_type === 'created') {
						thisApp.handleCreate(json.data)
					}
				}
			}
			console.log(this.wsCashflow)
		},
		toggleSelectAllSystems () {
			if (this.selectAllSystems) {
				this.paymentSystemSelected = []
			} else {
				this.paymentSystemSelected = Array.from(new Set(this.paymentSystems.map(el => el.id)));
			}
		},
		toggleSelectAllCreators () {
			if (this.selectAllCreators) {
				this.creatorsSelected = []
			} else {
				this.creatorsSelected = Array.from(new Set(this.creators.map(el => el)));
			}
		},
		paginationReset() {
			this.items = [];
			this.itemsLoaded = false;
			this.page = 1;
		},
		async getPaymentSystems() {
			this.paymentSystemsLoading = true;
			this.paymentSystemsLoaded = false;
			this.paymentSystems = await apiFinance.getPaymentSystems()
			this.paymentSystemsLoading = false;
			this.paymentSystemsLoaded = true;
		},
		async getCreators() {
			this.creatorsLoading = true;
			this.creatorsLoaded = false;
			this.creators = await apiFinanceCashflow.getCreators()
			this.creatorsLoading = false;
			this.creatorsLoaded = true;
		},
		async getRequests() {
			this.itemsLoading = true;
			this.itemsLoaded = false;
			const data = {
				page: this.page,
				search: this.search,
				status: this.tab,
				payment_system: this.paymentSystemSelected.join(','),
				created_by: this.creatorsSelected.join(','),
			}
			try {
				const response = await apiFinanceCashflow.getCashflowRequests(data);
				this.items = this.items.concat(response.results);
				this.itemsCount = response.count;
				this.page = response.next;
				this.itemsLoading = false;
				this.itemsLoaded = true;
				this.pageLoaded = true;
			} catch (error) {
				console.log(error)
			}
		},
		handleCreate(response) {
			const index = this.items.findIndex(item => item.id === response.id);
			if (index === -1) {
				this.items.unshift(response)
			}
		},
		handleUpdate(response) {
			const index = this.items.findIndex(item => item.id === response.id);
			if (index !== -1) {
				this.items[index] = response
			}
		},
		handleEditClick(info) {
			const index = this.items.findIndex(item => item.id === info.id);
			this.editDialogOpen = true;
			this.editedItem = Object.assign({}, info)
			this.editedIndex = index;
		},
		handleCreateClick() {
			this.editDialogOpen = true;
			this.editedItem = Object.assign({}, this.defaultItem)
		},
		handleCancelEditDialog() {
			this.editDialogOpen = false;
			this.editedItem = Object.assign({}, this.defaultItem)
			this.editedIndex = -1;
		},
		handleOpenCopyDialog(info) {
			console.log(info)
			this.copyDialogOpened = true;
			this.copiedItem = Object.assign({}, info)
		},
		handleCancelCopyDialog() {
			this.copyDialogOpened = false;
			this.copiedItem = Object.assign({}, this.defaultItem)
			this.copiedIndex = -1;
		},
		loadMore() {
			if (this.page && !this.itemsLoading) {
				this.getRequests()
			}
		},
	}
}
</script>

<template>
	<ScrollingPagination class="fill-height overflow-y-auto" @loadMore="loadMore">
		<div class="page-container pt-4 pb-10">
			<div class="page-wrap">

				<h1 class="text-h4 mb-8">{{ pageTitle }}</h1>

				<div class="mb-6">
					<v-btn
							color="primary"
							prepend-icon="mdi-plus"
							text="Create request"
							@click="handleCreateClick"
					></v-btn>
				</div>

				<v-tabs
						class="mb-10"
						center-active
						color="primary"
						v-model="tab"
				>
					<v-tab value="">All</v-tab>
					<v-tab value="accept">Provided</v-tab>
					<v-tab value="reject">Decline</v-tab>
				</v-tabs>

				<v-row justify="space-between" align="center">
					<v-col cols="auto">
						<v-row style="max-width: 100%; width: 440px">
							<v-col :cols="mdAndDown ? 12 : 6">
								<v-select
										v-model="paymentSystemSelected"
										variant="outlined"
										density="compact"
										:items="paymentSystems"
										label="Payment system"
										item-title="name"
										item-value="id"
										multiple
										hide-details
								>
									<template v-slot:prepend-item>
										<v-list-item
												title="Select All"
												@click="toggleSelectAllSystems"
										>
											<template v-slot:prepend>
												<v-checkbox-btn
														:color="selectSomeSystems ? 'primary' : undefined"
														:indeterminate="selectSomeSystems && !selectAllSystems"
														:model-value="selectSomeSystems"
												></v-checkbox-btn>
											</template>
										</v-list-item>
										<v-divider class="mt-2"></v-divider>
									</template>
									<template v-slot:selection="{ index }">
										<span v-if="index === 0" class="text-grey text-caption align-self-center">Selected items: {{ paymentSystemSelected.length }}</span>
									</template>
								</v-select>
							</v-col>
							<v-col :cols="mdAndDown ? 12 : 6">
								<v-select
										v-model="creatorsSelected"
										variant="outlined"
										density="compact"
										:items="creators"
										label="Request author"
										hide-details
										multiple
								>
									<template v-slot:prepend-item>
										<v-list-item
												title="Select All"
												@click="toggleSelectAllCreators"
										>
											<template v-slot:prepend>
												<v-checkbox-btn
														:color="selectSomeCreators ? 'primary' : undefined"
														:indeterminate="selectSomeCreators && !selectAllCreators"
														:model-value="selectSomeCreators"
												></v-checkbox-btn>
											</template>
										</v-list-item>
										<v-divider class="mt-2"></v-divider>
									</template>
									<template v-slot:selection="{ index }">
										<span v-if="index === 0" class="text-grey text-caption align-self-center">Selected items: {{ creatorsSelected.length }}</span>
									</template>
								</v-select>
							</v-col>
						</v-row>
					</v-col>
					<v-col cols="auto">
						<div style="max-width: 100%; width: 400px">
							<v-text-field
									v-model="search"
									variant="underlined"
									label="Search"
									append-inner-icon="mdi-magnify"
									density="compact"
							></v-text-field>
						</div>
					</v-col>
				</v-row>

				<template v-if="pageLoaded && !itemsLoading">
					<div class="text-subtitle-2 font-weight-bold mb-4">Number of requests: {{ itemsCount }}</div>
					<div class="mt-2" v-if="itemsCount > 0">
						<CashflowCard
								v-for="(item, index) in items"
								:key="index"
								:info="item"
								class="mb-6"
								@edit="handleEditClick"
								@copy="handleOpenCopyDialog"
						></CashflowCard>
					</div>

					<div v-else class="mt-10 text-body-1 text-center">
						<span>...</span><br>
						<span>Нет данных, соответствующих выбранным фильтрам</span>
					</div>
				</template>

				<template v-if="itemsLoading">
					<v-skeleton-loader type="heading" max-width="200" class="bg-transparent mb-2"></v-skeleton-loader>
					<v-skeleton-loader
							v-for="(item, index) in 3"
							:key="index"
							class="rounded-lg elevation-2 mb-6"
							type="table-heading, list-item-two-line, actions"
					></v-skeleton-loader>
				</template>

				<v-dialog v-model="editDialogOpen" fullscreen persistent transition="slide-x-transition" max-width="1100px" class="right-position">
					<CashflowEditDialog
							@copy="handleOpenCopyDialog"
							@close="handleCancelEditDialog"
							:edited="editedIndex"
							:info="editedItem"
							@update="handleUpdate"
							@create="handleCreate"
							:creators="creators"
							:creators-loaded="creatorsLoaded"
					/>
				</v-dialog>

				<v-dialog v-model="copyDialogOpened" fullscreen persistent transition="slide-x-transition" max-width="1000px" class="right-position">
					<CashflowCopyDialog
							@create="handleCreate"
							@close="handleCancelCopyDialog"
							:info="copiedItem"
							:creators="creators"
							:creators-loaded="creatorsLoaded"
					/>
				</v-dialog>

			</div>
		</div>
	</ScrollingPagination>
</template>

<style scoped>

</style>