<script>
import apiAds from "@/api/ads";

export default {
	name: "DonorCard",

	props: {
		info: {}
	},


	data() {
		return {
			formLoading: false,
		}
	},


	methods: {
		async handleUpdate() {
			this.formLoading = true;
			try {
				const data = {
					is_limited: false,
				};
				const response = await apiAds.editDonor(this.info.id, data);
				this.$emit('update', response);
			} catch (error) {
				console.log(error)
			} finally {
				this.formLoading = false;
			}
		},
	}
}
</script>

<template>
	<v-card :loading="formLoading" :disabled="formLoading" class="py-4 px-5">
		<v-row class="mb-6" no-gutters align="center" justify="space-between">
			<v-col cols="auto">
				<div class="d-flex align-center" :class="info.is_blacklist ? 'is-blacklist' : ''">
					<div :class="info.name ? '' : 'pa-4'" class="text-h6">{{ info.name }}</div>
					<v-icon v-if="info.is_blacklist" color="primary" class="ml-2">mdi-cancel</v-icon>
				</div>
				<div :class="info.speed_completion ? '' : 'pa-3'" class="d-flex align-center mt-2">
					<template v-if="info.speed_completion">
						<v-icon color="green" v-if="info.speed_completion < 45">mdi-emoticon-happy-outline</v-icon>
						<v-icon color="orange-darken-1" v-if="info.speed_completion >= 45 && info.speed_completion < 70">mdi-emoticon-neutral-outline</v-icon>
						<v-icon color="red-accent-2" v-if="info.speed_completion > 70">mdi-emoticon-sad-outline</v-icon>
						<div class="text-grey-darken-3 text-body-2 ml-2">Avg: {{ info.speed_completion }} days</div>
					</template>
				</div>
			</v-col>
			<v-col cols="auto">
				<v-btn icon="mdi-pencil" variant="text" @click="$emit('edit', info)"></v-btn>
			</v-col>
		</v-row>
		<div class="text-body-2 text-grey-darken-1 mb-2">Donor types:</div>
		<div v-if="info.types.length > 0" class="text-body-2 mb-4">
			<span v-for="(item, index) in info.types" :key="index"><template v-if="index > 0">, </template>{{item.name}}</span>
		</div>
		<div v-else class="text-body-2 mb-4">not specified</div>
		<div class="text-body-2 text-grey-darken-1 mb-2">Donor options:</div>
		<div v-if="info.options.length > 0" class="text-body-2 mb-4">
			<span v-for="(item, index) in info.options" :key="index"><template v-if="index > 0">, </template>{{item.name}}</span>
		</div>
		<div v-else class="text-body-2 mb-4">not specified</div>
		<div class="text-body-2 text-grey-darken-1 mb-2">Is limited:</div>
		<div class="d-flex align-center">
			<template v-if="info.is_limited">
				<v-icon class="mr-2" color="primary" @click="handleUpdate">mdi-checkbox-multiple-marked-circle-outline</v-icon><span class="py-1">Yes</span>
			</template>
			<template v-else>
				<span class="py-1">No</span>
			</template>
		</div>
	</v-card>
</template>

<style scoped>
.is-blacklist {
	opacity: .5;
}
</style>