<template>
	<v-navigation-drawer
			:absolute="mdAndDown"
			floating
			class="custom-shadow"
			width="230"
			location="left"
	>

		<div>
			<div class="pt-8 pb-16 px-4">
				<div v-if="userLoaded" class="text-center">
					<v-avatar variant="flat" color="primary" size="80" class="text-h5">{{ avatarLabel }}</v-avatar>
					<div class="text-subtitle-2 mt-2 text-uppercase">{{ userInfo.full_name }}</div>
					<p class="text-body-2 mt-1 text-grey">{{ setRole(userInfo.role_label) }}</p>
				</div>
				<template v-else>
					<v-skeleton-loader type="avatar" width="80" class="bg-transparent mx-auto"></v-skeleton-loader>
					<v-skeleton-loader type="sentences" class="bg-transparent mx-auto"></v-skeleton-loader>
				</template>
			</div>
		</div>

		<div v-if="userLoaded">

				<v-list variant="flat" color="secondary" nav class="leftbar-navigation text-body-2 text-uppercase text-center">

					<template v-if="userInfo.role === 'superuser'">
						<div class="text-caption text-center my-1 text-grey">Shifts</div>
						<v-divider class="mb-2"></v-divider>
						<v-list-item :to="({ name: 'Dashboard' })" exact>Main Page</v-list-item>
						<v-list-item :to="({ name: 'ShiftsHistory' })">Shifts history</v-list-item>
						<v-list-item :to="({ name: 'MyTeams' })">My teams</v-list-item>
						<v-list-item :to="({ name: 'Operators' })">Operators</v-list-item>
						<v-list-item :to="({ name: 'Models' })">Models</v-list-item>
						<v-list-item :to="({ name: 'ModelsPlan' })">Models Plan</v-list-item>
						<v-list-item href="https://app.ofmatrix.click/" target="_blank">Spammer <v-icon>mdi-open-in-new</v-icon></v-list-item>
						<div class="text-caption text-center my-1 text-grey">Marketing</div>
						<v-divider class="mb-2"></v-divider>
						<v-list-item :to="({ name: 'Ads' })">Ads</v-list-item>
						<v-list-item :to="({ name: 'CalendarAds' })">Calendar ads</v-list-item>
						<v-list-item :to="({ name: 'PivotTable' })">Сводная таблица</v-list-item>
						<v-list-item :to="({ name: 'BlackList' })">Черный список</v-list-item>
						<v-list-item :to="({ name: 'Romi' })">Отрицательный ROMI</v-list-item>
						<v-list-item :to="({ name: 'PivotTableByModel' })">Сводная по модели</v-list-item>
						<v-list-item :to="({ name: 'BudgetMarketing' })">Бюджет маркетинга</v-list-item>
						<div class="text-caption text-center my-1 text-grey">Finance</div>
						<v-divider class="mb-2"></v-divider>
						<v-list-item :to="({ name: 'Receivable' })">Receivable</v-list-item>
						<v-list-item :to="({ name: 'Forecast' })">Forecast promo</v-list-item>
						<v-list-item :to="({ name: 'WeekDebt' })">Week debt</v-list-item>
						<v-list-item :to="({ name: 'IncomeFansTracking' })">Income Fans Tracking</v-list-item>
					</template>

					<template v-if="userInfo.role === 'team_lead' || userInfo.role === 'senior_operator' || userInfo.role === 'operator'">
						<v-list-item :to="({ name: 'Dashboard' })" exact>Main Page</v-list-item>
						<v-list-item v-if="userInfo.role === 'team_lead' || userInfo.role === 'senior_operator'" :to="({ name: 'ShiftsHistory' })">Shifts history</v-list-item>
						<v-list-item v-if="userInfo.role === 'team_lead' || userInfo.role === 'senior_operator'" :to="({ name: 'MyTeams' })">My teams</v-list-item>
						<v-list-item v-if="userInfo.role === 'team_lead' || userInfo.role === 'senior_operator'" :to="({ name: 'Models' })">Models</v-list-item>
						<v-list-item v-if="userInfo.role === 'team_lead' || userInfo.role === 'senior_operator'" :to="({ name: 'Operators' })">Operators</v-list-item>
<!--						<v-list-item v-if="userInfo.role === 'operator'" :to="({ name: 'CustomRequests' })">Custom request</v-list-item>-->
						<v-list-item href="https://app.ofmatrix.click/" target="_blank">Spammer <v-icon>mdi-open-in-new</v-icon></v-list-item>
						<template v-if="userInfo.role === 'team_lead' || userInfo.role === 'senior_operator'">
							<div class="text-caption text-center my-1 text-grey">Marketing</div>
							<v-divider class="mb-2"></v-divider>
							<v-list-item :to="({ name: 'CalendarAds' })">Calendar ads</v-list-item>
						</template>
					</template>

					<template v-if="userInfo.role === 'hom' || userInfo.role === 'marketer'">
						<v-list-item :to="({ name: 'Dashboard' })" exact>Главная страница</v-list-item>
						<v-list-item :to="({ name: 'Marketers' })" v-if="userInfo.role === 'hom'">Маркетологи</v-list-item>
						<v-list-item :to="({ name: 'PivotTable' })">Сводная таблица</v-list-item>
						<v-list-item :to="({ name: 'BlackList' })">Черный список</v-list-item>
						<v-list-item :to="({ name: 'Romi' })">Отрицательный ROMI</v-list-item>
						<v-list-item :to="({ name: 'PivotTableByModel' })">Сводная по модели</v-list-item>
						<v-list-item :to="({ name: 'BudgetMarketing' })">Бюджет маркетинга</v-list-item>
						<v-list-item :to="({ name: 'DonorList' })">Donors list</v-list-item>
						<v-list-item :to="({ name: 'CalendarAds' })">Calendar ads</v-list-item>
						<div class="text-caption text-center my-1 text-grey">SMM</div>
						<v-divider class="mb-2"></v-divider>
						<v-list-item :to="({ name: 'Tracking' })" :class="$route.meta.parent === 'tracking' ? 'v-list-item--active bg-secondary' : ''">Tracking list</v-list-item>
						<v-list-item :to="({ name: 'TrackingHistory' })" :class="$route.meta.parent === 'tracking-history' ? 'v-list-item--active bg-secondary' : ''">History</v-list-item>
						<div class="text-caption text-center my-1 text-grey">Finance</div>
						<v-divider class="mb-2"></v-divider>
						<v-list-item :to="({ name: 'Receivable' })">Receivable</v-list-item>
						<v-list-item :to="({ name: 'TotalSpend' })">Total Spend</v-list-item>
						<v-list-item :to="({ name: 'Forecast' })">Forecast promo</v-list-item>
						<v-list-item :to="({ name: 'WeekDebt' })">Week debt</v-list-item>
					</template>

					<template v-if="userInfo.role === 'hobd' || userInfo.role === 'business_dev'">
						<v-list-item :to="({ name: 'Dashboard' })" exact>Главная страница</v-list-item>
						<v-list-item :to="({ name: 'PivotTable' })">Сводная таблица</v-list-item>
						<v-list-item :to="({ name: 'BlackList' })">Черный список</v-list-item>
						<v-list-item :to="({ name: 'Romi' })">Отрицательный ROMI</v-list-item>
						<v-list-item :to="({ name: 'PivotTableByModel' })">Сводная по модели</v-list-item>
						<v-list-item :to="({ name: 'Tracking' })" :class="$route.meta.parent === 'tracking' ? 'v-list-item--active bg-secondary' : ''">Tracking list</v-list-item>
					</template>

					<template v-if="userInfo.role === 'hof' || userInfo.role === 'financier'">
						<v-list-item :to="({ name: 'Receivable' })">Receivable</v-list-item>
						<v-list-item :to="({ name: 'TotalSpend' })">Total Spend</v-list-item>
						<v-list-item v-if="userInfo.role === 'hof'" :to="({ name: 'Forecast' })">Forecast promo</v-list-item>
						<v-list-item :to="({ name: 'Payments' })">Payments</v-list-item>
						<v-list-item :to="({ name: 'Payouts' })">Payouts</v-list-item>
						<v-list-item :to="({ name: 'WeekDebt' })">Week debt</v-list-item>
						<v-list-item :to="({ name: 'IncomeFansTracking' })">Income Fans Tracking</v-list-item>
						<v-list-item :to="({ name: 'Ads' })">Ads</v-list-item>
						<v-list-item :to="({ name: 'BudgetFinance' })">Budget</v-list-item>
						<v-list-item :to="({ name: 'Cashflow' })">Cashflow</v-list-item>
						<v-list-item :to="({ name: 'CashflowIncome' })">Cashflow Income</v-list-item>
						<div class="text-caption text-center mt-3 mb-1 text-grey">Marketing</div>
						<v-divider class="mb-2"></v-divider>
						<v-list-item :to="({ name: 'BudgetMarketing' })">Budget marketing</v-list-item>
					</template>

					<template v-if="userInfo.role === 'smm' || userInfo.role === 'smm_of'">
						<v-list-item :to="({ name: 'Tracking' })" :class="$route.meta.parent === 'tracking' ? 'v-list-item--active bg-secondary' : ''">Tracking list</v-list-item>
						<v-list-item :to="({ name: 'TrackingHistory' })" :class="$route.meta.parent === 'tracking-history' ? 'v-list-item--active bg-secondary' : ''">History</v-list-item>
					</template>

					<template v-if="userInfo.role === 'researcher'">
						<v-list-item :to="({ name: 'DonorsChecker' })">Donors checker</v-list-item>
					</template>

					<template v-if="userInfo.role === 'hocm' || this.userInfo.role === 'client_manager'">
						<v-list-item :to="({ name: 'Managers' })" v-if="userInfo.role === 'hocm'">Managers</v-list-item>
						<v-list-item :to="({ name: 'Databook' })" :class="$route.meta.parent === 'databook' ? 'v-list-item--active bg-secondary' : ''">Databook</v-list-item>
						<v-list-item :to="({ name: 'CalendarWrap' })">Calendar</v-list-item>
						<v-list-item :to="({ name: 'Feed' })">Feed</v-list-item>
					</template>

					<template v-if="userInfo.role === 'spectator'">
						<v-list-item :to="({ name: 'Shifts' })">Shifts</v-list-item>
						<v-list-item :to="({ name: 'ShiftsHistory' })">Shifts history</v-list-item>
					</template>

				</v-list>

		</div>

	</v-navigation-drawer>
</template>

<script>
  import {mapState} from 'vuex'
  import { useDisplay } from 'vuetify'

  export default {
    setup () {
      // Destructure only the keys we want to use
      const { lgAndUp, mdAndDown } = useDisplay();

      return { lgAndUp, mdAndDown }
    },

    name: 'TheSidebarLeft',


    props: {

    },


    data() {
      return {

      }
    },


    computed: {
      ...mapState({
        userInfo: state => state.user.info,
        userLoading: state => state.user.userLoading,
        userLoaded: state => state.user.userLoaded,
      }),
			avatarLabel() {
				return ((this.userInfo.full_name.match(/(^|\s)\S/g)).join('')).replace(/ /g, '')
			},
    },


    mounted() {

    },


    methods: {
      setRole(value) {
        if (value === 'Super user') {
          return 'Super user'
        }
        if (value === 'Teamlead') {
          return 'Teamlead'
        }
        if (value === 'Senior Operator') {
          return 'Senior Operator'
        }
        if (value === 'Operator') {
          return 'Operator'
        }
        return value
      }
    }
  }
</script>

<style lang="less">
	.v-theme--light .leftbar-navigation {
		--v-hover-opacity: 0.1;
	}

	.leftbar-navigation {

		& .v-list-item > .v-list-item__overlay {
			background-color: rgba(var(--v-theme-secondary));
		}

		& .v-list-item > .v-list-item__content {
			transition: all .3s ease;
		}

		& .v-list-item:hover {
			color: rgba(var(--v-theme-secondary));
		}
	}
</style>