import {
  TOGGLE_SIDEBAR,
} from './constants'
import { RESET_STATE } from '../../constants'
import resetState from '../../helpers/resetState'

const initialState = {
  sidebar: false,
}

const mutations = {
  [TOGGLE_SIDEBAR](state, value) {
    state.sidebar = value;
  },
  [RESET_STATE](state) {
    resetState(state, initialState)
  },
}

const actions = {
    resetState({ commit }) {
    commit(RESET_STATE)
  }
}

export default {
  namespaced: true,
  state: { ...initialState },
  mutations,
  actions
}
