<template>
	<v-app-bar class="elevation-0 custom-shadow">
		<v-app-bar-nav-icon :icon="show ? 'mdi-arrow-collapse-horizontal' : 'mdi-arrow-expand-horizontal'" variant="outlined" color="grey" @click.stop="toggle"></v-app-bar-nav-icon>
		<v-spacer></v-spacer>
		<v-btn
				icon="mdi-exit-to-app"
				variant="outlined"
				color="grey"
				@click="logout"
		></v-btn>
	</v-app-bar>
</template>

<script>
import {TOGGLE_SIDEBAR} from '@/store/modules/main-view/constants'
import {mapState} from "vuex";

export default {
	name: 'BaseHeader',


	props: {
		show: {}
	},


	data: () => ({}),


	computed: {
		...mapState ({
			sidebar: state => state.mainView.sidebar,
		}),
	},


	methods: {
		toggle() {
			this.$store.commit(`mainView/${TOGGLE_SIDEBAR}`, !this.sidebar);
			this.$emit('toggleSidebar');
		},
		logout() {
			this.$emit('logout')
			this.$store.dispatch('resetState')
		},
	}
}
</script>

<style scoped>

</style>