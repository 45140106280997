<script>
import FullCalendar from "@fullcalendar/vue3";
import {mapState} from "vuex";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import apiAds from "@/api/ads";

export default {
	name: "CalendarStartAds",


	components: {
		FullCalendar
	},


	data() {
		return {
			calendarTitle: '',
			selectedModel: [],
			selectedMonth: {
				month: new Date().getMonth(),
				year: new Date().getFullYear()
			},
			calendarOptions: {},
			itemsLoading: false,
			itemsLoaded: false,
		}
	},


	computed: {
		...mapState({
			commonListModels: state => state.user.commonListModels,
			commonListModelsLoaded: state => state.user.commonListModelsLoaded,
			sidebar: state => state.mainView.sidebar,
		}),
		selectAllModels () {
			return this.selectedModel.length === this.commonListModels.length
		},
		selectSomeModels () {
			return this.selectedModel.length > 0
		},
	},


	created() {
		// if (this.commonListModelsLoaded) {
		// 	this.selectedModel.push(this.commonListModels[0].id);
		// }
		this.getCalendarAds();
		this.setCalendarOptions();
		// if (this.commonListModelsLoaded) {
		// 	this.getCalendarAds()
		// }
	},


	watch: {
		sidebar() {
			this.timer = setTimeout(() => {
				this.$refs.fullCalendar.getApi().updateSize();
			}, 400);
		},
		// commonListModelsLoaded() {
		// 	this.selectedModel.push(this.commonListModels[0].id);
		// },
		selectedMonth: {
			handler() {
				this.$refs.fullCalendar.getApi().gotoDate(new Date(this.selectedMonth.year, this.selectedMonth.month, 1));
				this.getCalendarAds()
			},
			deep: true
		},
		selectedModel: {
			handler() {
				this.getCalendarAds()
			},
			deep: true
		},
	},


	methods: {
		toggleSelectAllModels () {
			if (this.selectAllModels) {
				this.selectedModel = []
			} else {
				this.selectedModel = Array.from(new Set(this.commonListModels.map(el => el.id)));
			}
		},

		setCalendarOptions() {
			this.calendarOptions = {
				initialDate: new Date(this.selectedMonth.year, this.selectedMonth.month, 1),
				plugins: [dayGridPlugin, interactionPlugin],
				headerToolbar: false,
				initialView: 'dayGridMonth',
				selectable: true,
				// select: (info) => {
				// 	this.handleDateClick(info)
				// },
				// eventClick: (info) => {
				// 	this.handleEventClick(info)
				// },
				dayMaxEvents: true,
				views: {
					dayGrid: {
						dayMaxEvents: 4,
					}
				},
				events: [{title: '', start: new Date(), end: new Date(), color: 'transparent'}],
			}
		},

		setEventColor(status) {
			if (status === 'draft') return 'grey-darken-1';
			if (status === 'progress') return 'amber-darken-1';
			if (status === 'done') return 'green';
			if (status === 'other_type') return 'indigo';
			if (status === 'swap') return 'purple'
			if (status === 'problem') return 'red-lighten-1'
		},

		setEventIcon(status) {
			if (status === 'draft') return 'mdi-pencil';
			if (status === 'progress') return 'mdi-play';
			if (status === 'done') return 'mdi-check';
			if (status === 'other_type') return 'mdi-advertisements';
			if (status === 'swap') return 'mdi-swap-horizontal';
			if (status === 'problem') return 'mdi-exclamation-thick';
		},

		toPrevMonth() {
			if (this.selectedMonth.month === 0) {
				this.selectedMonth.year = this.selectedMonth.year - 1;
				this.selectedMonth.month = 11
			} else {
				this.selectedMonth.month = this.selectedMonth.month - 1
			}
		},

		toNextMonth() {
			if (this.selectedMonth.month === 11) {
				this.selectedMonth.year = this.selectedMonth.year + 1;
				this.selectedMonth.month = 0
			} else {
				this.selectedMonth.month = this.selectedMonth.month + 1
			}
		},

		getCalendarTitle() {
			this.calendarTitle = this.$refs.fullCalendar.getApi().view.title;
		},

		formatingDate(date, format) {
			const year = date.getFullYear();
			const month = (date.getMonth() + 1).toString().padStart(2, '0');
			const day = (date.getDate().toString().padStart(2, '0'));
			if (format === 'dd.MM.yyyy') {
				return `${day}.${month}.${year}`;
			} else if (format === 'yyyy-MM-dd') {
				return `${year}-${month}-${day}`;
			}
		},

		async getCalendarAds() {
			this.itemsLoading = true;
			this.itemsLoaded = false;
			const params = {
				date_after: this.formatingDate(new Date(this.selectedMonth.year, this.selectedMonth.month, 1), 'yyyy-MM-dd'),
				date_before: this.formatingDate(new Date(this.selectedMonth.year, this.selectedMonth.month + 1, 0), 'yyyy-MM-dd'),
			};
			if (this.selectedModel.length === this.commonListModels.length) {
				params.only_fans_models = '';
			} else {
				params.only_fans_models = this.selectedModel.join(',');
			}
			try {
				const response = await apiAds.getCalendarAds(params);
				await this.generateItems(response);
				this.itemsLoading = false;
				this.itemsLoaded = true;
				this.getCalendarTitle()
			} catch(error) {
				console.log(error)
			}
		},

		generateItems(response) {
			this.items = [];
			response.forEach((el) => {
				let newItem = {
					id: el.id,
					title: el.only_fans_model,
					start: el.calendar_start_date,
					// end: new Date(new Date(el.calendar_end_date).getFullYear(), new Date(el.calendar_end_date).getMonth(), new Date(el.calendar_end_date).getDate() + 1),
					extendedProps: {
						status: el.status,
					},
					color: 'transparent',
				};
				if (el.calendar_end_date !== el.calendar_start_date) {
					newItem.end = new Date(new Date(el.calendar_end_date).getFullYear(), new Date(el.calendar_end_date).getMonth(), new Date(el.calendar_end_date).getDate() + 1);
				}
				this.items.push(newItem)
			});
			this.calendarOptions.events = this.items
		},
	}
}
</script>

<template>
	<div class="page-container pt-4 pb-10">
		<div class="page-wrap">

			<h1 class="text-h4 mb-8">Calendar</h1>

			<div>

				<v-row justify="space-between" align="center" class="mb-3">
					<v-col cols="auto">
						<v-row no-gutters>
							<v-col cols="auto">
								<v-autocomplete
										class="mr-2"
										style="z-index: 2; width: 250px"
										v-model="selectedModel"
										:loading="!commonListModelsLoaded"
										:disabled="!commonListModelsLoaded"
										density="compact"
										placeholder="Model"
										variant="outlined"
										item-title="nickname"
										item-value="id"
										:items="commonListModels"
										hide-details
										multiple
								>
									<template v-slot:prepend-item>
										<v-list-item
												title="Select All"
												@click="toggleSelectAllModels"
										>
											<template v-slot:prepend>
												<v-checkbox-btn
														:color="selectSomeModels ? 'primary' : undefined"
														:indeterminate="selectSomeModels && !selectAllModels"
														:model-value="selectSomeModels"
												></v-checkbox-btn>
											</template>
										</v-list-item>
										<v-divider class="mt-2"></v-divider>
									</template>
									<template #selection="{ index }">
										<span v-if="index === 0" class="text-grey text-caption align-self-center">Selected items: {{ selectedModel.length }}</span>
									</template>
								</v-autocomplete>
							</v-col>
							<v-col cols="auto">
								<VueDatePicker
										v-model="selectedMonth"
										style="z-index: 2"
										class="clearable-false density-compact"
										auto-apply
										month-picker
										:clearable="false"
										:enable-time-picker="false"
								/>
							</v-col>
						</v-row>
					</v-col>
					<v-col cols="auto">
						<div class="d-flex align-center">
							<v-btn variant="text" density="comfortable" icon="mdi-chevron-left" @click="toPrevMonth"></v-btn>
							<v-btn class="ml-1" variant="text" density="comfortable" icon="mdi-chevron-right" @click="toNextMonth"></v-btn>
							<div class="text-h5 font-weight-bold ml-3">{{ calendarTitle }}</div>
						</div>
					</v-col>
				</v-row>

				<v-sheet elevation="1">
					<v-row no-gutters>
						<v-col cols="auto">

						</v-col>
						<v-divider vertical></v-divider>
						<v-col>
							<div class="pa-4 position-relative">
								<FullCalendar
										ref="fullCalendar"
										:options='calendarOptions'
								>
									<template v-slot:eventContent='arg'>
										<v-chip
												class="w-100 event-chip"
												:color="setEventColor(arg.event.extendedProps.status)"
												:prepend-icon="setEventIcon(arg.event.extendedProps.status)"
										>
											<span class="text-cover">{{ arg.event.title }}</span>
										</v-chip>
									</template>
								</FullCalendar>
								<div v-if="!itemsLoaded" class="calendar-cover">
									<v-progress-circular size="150" width="14" indeterminate color="primary"></v-progress-circular>
								</div>
							</div>
						</v-col>
					</v-row>

				</v-sheet>

			</div>

		</div>
	</div>
</template>

<style lang="less">
.calendar-cover {
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	background-color: rgba(255, 255, 255, 0.7);
	z-index: 1;
	display: flex;
	align-items: center;
	justify-content: center;
}

.v-autocomplete__selection + .v-autocomplete__selection {
	display: none;
}

.event-chip {
	display: flex;

	& .v-chip__prepend {
		flex: 0 0 20px;
	}

	& .v-chip__content {
		flex: 0 0 calc(100% - 20px);
		overflow: hidden;
	}
}

.text-cover {
	overflow: hidden;
	text-overflow: ellipsis;
}

.fc-daygrid-more-link {
	display: block !important;
	float: unset !important;
	padding: 8px !important;
	text-align: center;
	font-weight: bold;
	margin-top: 2px !important;
}
</style>