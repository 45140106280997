<template>
	<div class="page-container pt-4 pb-10 fill-height">
		<div class="fill-height d-flex flex-column">

			<div class="flex-0-0">
				<h1 class="text-h4 mb-8">Total spend</h1>
				<v-row no-gutters align="center" class="justify-space-between mb-4">
					<v-col class="pa-0 v-col-auto">
						<v-row>
							<v-col cols="auto">
								<VueDatePicker
										class="density-compact clearable-false"
										v-model="date"
										range
										auto-apply
										:format="format"
										:clearable="false"
								/>
							</v-col>
							<v-col cols="auto">
								<v-select
										style="width: 150px"
										v-model="type"
										density="compact"
										variant="outlined"
										:items="['date', 'buy date', 'refund date', 'by date counter', 'date phantom cost']"
										hide-details
								></v-select>
							</v-col>
							<v-col cols="auto">
								<v-select
										style="width: 170px"
										v-model="promoSelected"
										variant="outlined"
										density="compact"
										:items="promos"
										label="Promos"
										item-title="name"
										item-value="id"
										hide-details
								></v-select>
							</v-col>
						</v-row>
					</v-col>
					<v-col class="pa-0 v-col-auto">
						<v-btn :disabled="!tableLoaded" :loading="!tableLoaded" color="primary" @click="copyTable">Copy table</v-btn>
					</v-col>
				</v-row>
			</div>

			<div class="flex-1-1 overflow-y-auto">
				<div class="overflow-x-auto fill-height px-1">

					<v-skeleton-loader v-if="!itemsLoaded" type="table-tbody" class="bg-transparent mb-6"></v-skeleton-loader>

					<template v-else>
						<div class="d-flex w-fit bg-background position-sticky" style="top: 0; z-index: 1">
							<div class="pa-2 pb-3 text-body-1 font-weight-medium" style="width: 150px">
								<template v-if="type === 'date'">Date</template>
								<template v-if="type === 'buy date'">Buy date</template>
								<template v-if="type === 'refund date'">Refund date</template>
								<template v-if="type === 'by date counter'">By date counter</template>
								<template v-if="type === 'date phantom cost'">Date phantom cost</template>
							</div>
							<div
									v-for="(item, index) in commonListModels"
									:key="index"
									class="pa-2 pb-3 text-body-1 font-weight-medium"
									:style="{width: '150px', order: item.index_number}"
							>
								{{ item.nickname }}
							</div>
						</div>
						<template v-if="items.length > 0">
							<div class="bg-white w-fit rounded-lg elevation-1 mb-2">
								<template v-for="(item, index) in items" :key="index">
									<v-divider v-if="index > 0"></v-divider>
									<div class="d-flex">
										<div :class="tableCellClass" style="width: 150px">{{ item.date }}</div>
										<template v-for="(model, modelIndex) in commonListModels" :key="modelIndex">
											<div :class="tableCellClass" :style="{width: '150px', order: model.index_number}">{{ checkMatch(model, item.promos) }}</div>
										</template>
									</div>
								</template>
							</div>
						</template>
						<template v-else>
							<div class="mt-10 text-body-1 text-center">
								<span>...</span><br>
								<span>Нет данных, соответствующих выбранным фильтрам</span>
							</div>
						</template>
					</template>

				</div>
			</div>

		</div>
	</div>
</template>

<script>
import apiFinance from "@/api/finance";
import {mapState} from "vuex";
import apiAds from "@/api/ads";

export default {
	name: 'TotalSpendPage',


	components: {},


	data() {
		return {
			tableCellClass: 'px-2 py-3 text-no-wrap overflow-hidden text-overflow-ellipsis text-body-2',
			date: this.setDefaultDateRange(),
			format: 'dd.MM.yyyy',
			itemsLoaded: false,
			items: [],
			tableLoaded: false,
			table: '',
			type: 'date',
			filteredModels: [],
			promos: [],
			promosLoading: false,
			promosLoaded: false,
			promoSelected: '',
		}
	},


	computed: {
		...mapState({
			commonListModels: state => state.user.commonListModels,
			commonListModelsLoaded: state => state.user.commonListModelsLoaded,
		}),
	},


	created() {
		this.getPromos();
		if (this.commonListModelsLoaded) {
			this.getTotalSpend();
			this.getTable();
			this.filteringModelsWithoutIndex()
		}
	},


	watch: {
		date() {
			this.getTotalSpend();
			this.getTable();
		},
		type() {
			this.getTotalSpend();
			this.getTable();
		},
		promoSelected() {
			this.getTotalSpend();
			this.getTable();
		},
		commonListModelsLoaded(value) {
			if (value) {
				this.getTotalSpend();
				this.getTable();
				this.filteringModelsWithoutIndex()
			}
		},
	},


	methods: {
		filteringModelsWithoutIndex() {
			this.filteredModels = this.commonListModels.filter((item) => item.index_number)
		},
		setDefaultDateRange() {
			const now = new Date();
			const start = new Date(now.getFullYear(), 0, 1);
			const end = new Date(now.getFullYear(), now.getMonth() + 1, 0);
			return [start, end]
		},
		formatingDate(date, format) {
			const year = date.getFullYear();
			const month = (date.getMonth() + 1).toString().padStart(2, '0');
			const day = (date.getDate().toString().padStart(2, '0'));
			if (format === 'dd.MM.yyyy') {
				return `${day}.${month}.${year}`;
			} else if (format === 'yyyy-MM-dd') {
				return `${year}-${month}-${day}`;
			}
		},
		async getPromos() {
			try {
				this.promosLoading = true;
				this.promosLoaded = false;
				const response = await apiAds.getPromos();
				const friends = response.find(item => item.name === 'Friends for model');
				const gg = response.find(item => item.name === 'GG');
				this.promos.push({id: '', name: 'All promos'});
				this.promos.push(friends);
				this.promos.push(gg);
				this.promosLoaded = true;
				this.promosLoading = false;
			} catch (error) {
				console.log('error', error)
			}
		},
		async getTotalSpend() {
			this.itemsLoaded = false;
			const params = {
				date_before: this.formatingDate(this.date[1], 'yyyy-MM-dd'),
				date_after: this.formatingDate(this.date[0], 'yyyy-MM-dd'),
				promo: this.promoSelected,
			}
			if (this.type === 'buy date') params.by_buy_date = true;
			if (this.type === 'refund date') params.by_refund_date = true;
			if (this.type === 'by date counter') params.by_date_counter = true;
			if (this.type === 'date phantom cost') params.by_date_phantom_cost = true;
			try {
				this.items = await apiFinance.getTotalSpend(params);
				this.itemsLoaded = true;
			} catch (error) {
				console.log(error)
			}
		},
		async getTable() {
			this.tableLoaded = false;
			const params = {
				date_before: this.formatingDate(this.date[1], 'yyyy-MM-dd'),
				date_after: this.formatingDate(this.date[0], 'yyyy-MM-dd'),
				promo: this.promoSelected,
			}
			if (this.type === 'buy date') params.by_buy_date = true;
			if (this.type === 'refund date') params.by_refund_date = true;
			if (this.type === 'by date counter') params.by_date_counter = true;
			if (this.type === 'date phantom cost') params.by_date_phantom_cost = true;
			try {
				this.table = await apiFinance.copyTable(params);
				this.tableLoaded = true;
			} catch (error) {
				console.log(error)
			}
		},
		checkMatch(model, promos) {
			const target = promos.find(promo => promo.nickname === model.nickname)
			if (target) {
				return target.cost
			} else {
				return  '-'
			}
		},
		copyTable() {
			window.navigator.clipboard.writeText(this.table.copy_data)

			// const unsecuredCopyToClipboard = (text) => {
			// 	const textArea = document.createElement("textarea");
			// 	textArea.value=text;
			// 	document.body.appendChild(textArea);
			// 	textArea.focus();
			// 	textArea.select();
			// 	try{
			// 		document.execCommand('copy')
			// 	} catch(err) {
			// 		console.error('Unable to copy to clipboard',err)
			// 	}
			// 	document.body.removeChild(textArea)};
			//
			// /**
			//  * Copies the text passed as param to the system clipboard
			//  * Check if using HTTPS and navigator.clipboard is available
			//  * Then uses standard clipboard API, otherwise uses fallback
			//  */
			// const copyToClipboard = (content) => {
			// 	if (window.isSecureContext && navigator.clipboard) {
			// 		navigator.clipboard.writeText(content);
			// 	} else {
			// 		unsecuredCopyToClipboard(content);
			// 	}
			// };
		}
	}
}
</script>

<style scoped>

</style>